import React from 'react';
import { useDroppable } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import TaskCard from './TaskCard';
import { useTranslation } from 'react-i18next';

function Column({ columnId, title, taskIds, tasks }) {
  const { t } = useTranslation();
  const { isOver, setNodeRef } = useDroppable({ id: columnId });

  const columnStyle = {
    width: '320px',
    minHeight: '500px',
    background: 'linear-gradient(to bottom, #ffffff, #f0f0f0)',
    borderRadius: '12px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    transition: 'all 0.3s ease',
    transform: isOver ? 'scale(1.02)' : 'scale(1)',
    boxShadow: isOver ? '0 6px 12px rgba(0, 0, 0, 0.15)' : '0 4px 6px rgba(0, 0, 0, 0.1)',
  };

  const columnHeaderStyle = {
    padding: '16px',
    backgroundColor: '#4a90e2',
    color: 'white',
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px',
    fontWeight: 'bold',
    fontSize: '1.2rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  };

  const taskCountStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    padding: '4px 10px',
    borderRadius: '12px',
    fontSize: '0.9rem',
  };

  const taskListStyle = {
    padding: '16px',
    flex: 1,
    overflowY: 'auto',
  };

  return (
    <div ref={setNodeRef} style={columnStyle}>
      <div style={columnHeaderStyle}>
        <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{title}</span>
        <span style={taskCountStyle}>{taskIds.length}</span>
      </div>
      <SortableContext items={taskIds} strategy={verticalListSortingStrategy}>
        <div style={taskListStyle}>
          {taskIds.length > 0 ? (
            taskIds.map((taskId) => (
              <TaskCard key={taskId} id={taskId} task={tasks[taskId]} />
            ))
          ) : (
            <div style={{ textAlign: 'center', color: '#666', marginTop: '20px' }}>
              {t('Drag tasks here')}
            </div>
          )}
        </div>
      </SortableContext>
    </div>
  );
}


export default Column;