import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, onSnapshot, addDoc, doc, updateDoc, deleteDoc, query, where, getDocs } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { db, auth } from '../../firebase';
import VerticalMenu from '../VerticalMenu';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Modal, Input, Button, Card, Tag, Space, Typography, 
  Layout, Row, Col, Tooltip, Avatar, Dropdown, Menu,
  Progress, ColorPicker, Select, Popconfirm, message, Empty, Drawer
} from 'antd';
import { 
  PlusOutlined, EditOutlined, DeleteOutlined, 
  SearchOutlined, SortAscendingOutlined, FilterOutlined, MenuOutlined
} from '@ant-design/icons';
import { formatDistanceToNow } from 'date-fns';
import { useMediaQuery } from 'react-responsive';

const { Text, Title } = Typography;
const { Header, Content } = Layout;
const { Option } = Select;

const COLORS = [
  '#FF5733', '#33FF57', '#3357FF', '#F333FF', '#FFBD33', '#33FFBD', '#5733FF', 
  '#FF33A1', '#33D4FF', '#FF8F33', '#33FF6B', '#A133FF', '#FF335E', '#33FFD4', 
  '#338AFF', '#FFD433'
];

function ProjectsPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [addProjectModalVisible, setAddProjectModalVisible] = useState(false);
  const [editProjectModalVisible, setEditProjectModalVisible] = useState(false);
  const [currentProject, setCurrentProject] = useState(null);
  const [newProject, setNewProject] = useState({ name: '', color: COLORS[0], tags: [] });
  const [allTags, setAllTags] = useState([]);
  const [recentColors, setRecentColors] = useState([]);
  const [sortBy, setSortBy] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');
  const [filterTag, setFilterTag] = useState('All');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const userId = auth.currentUser.uid;

  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    const projectsRef = collection(db, 'projects');
    const q = query(projectsRef, where('userId', '==', userId));
    const unsubscribe = onSnapshot(q, snapshot => {
      const fetchedProjects = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProjects(fetchedProjects);
      
      const tags = new Set();
      const colors = new Set();
      fetchedProjects.forEach(project => {
        if (project.tags) {
          project.tags.forEach(tag => tags.add(tag));
        }
        if (project.color) {
          colors.add(project.color);
        }
      });
      setAllTags(['All', ...Array.from(tags)]);
      setRecentColors(Array.from(colors).slice(0, 10));
    });
    return () => unsubscribe();
  }, [userId]);

  const filteredAndSortedProjects = useMemo(() => {
    return projects
      .filter(project => 
        project.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (filterTag === 'All' || (project.tags && project.tags.includes(filterTag)))
      )
      .sort((a, b) => {
        if (sortBy === 'name') {
          return sortOrder === 'asc' 
            ? a.name.localeCompare(b.name)
            : b.name.localeCompare(a.name);
        } else if (sortBy === 'date') {
          return sortOrder === 'asc'
            ? new Date(a.createdAt) - new Date(b.createdAt)
            : new Date(b.createdAt) - new Date(a.createdAt);
        }
        return 0;
      });
  }, [projects, searchTerm, filterTag, sortBy, sortOrder]);

  const handleAddProject = async () => {
    if (newProject.name.trim()) {
      try {
        await addDoc(collection(db, 'projects'), { 
          ...newProject,
          userId, 
          createdAt: new Date().toISOString()
        });
        setNewProject({ name: '', color: COLORS[0], tags: [] });
        setAddProjectModalVisible(false);
        message.success(t('Project added successfully'));
      } catch (error) {
        console.error("Error adding project: ", error);
        message.error(t('Error adding project'));
      }
    }
  };

  const handleEditProject = async () => {
    if (currentProject) {
      const projectRef = doc(db, 'projects', currentProject.id);
      await updateDoc(projectRef, {
        name: currentProject.name,
        color: currentProject.color,
        tags: currentProject.tags
      });
      setEditProjectModalVisible(false);
      setCurrentProject(null);
      message.success(t('Project updated successfully'));
    }
  };

  const handleDeleteProject = async (project) => {
    try {
      const featuresRef = collection(db, 'features');
      const featuresQuery = query(featuresRef, where('projectId', '==', project.id));
      const featuresSnapshot = await getDocs(featuresQuery);
      
      const batch = db.batch();
      featuresSnapshot.forEach((featureDoc) => {
        batch.delete(featureDoc.ref);
        
        const tasksRef = collection(db, 'tasks');
        const tasksQuery = query(tasksRef, where('featureId', '==', featureDoc.id));
        getDocs(tasksQuery).then(tasksSnapshot => {
          tasksSnapshot.forEach(taskDoc => {
            batch.delete(taskDoc.ref);
          });
        });
      });
      
      batch.delete(doc(db, 'projects', project.id));
      
      await batch.commit();
      message.success(t('Project and associated items deleted successfully'));
    } catch (error) {
      console.error("Error deleting project: ", error);
      message.error(t('Error deleting project'));
    }
  };

  const getInitials = (name) => {
    return name.split(' ').slice(0, 2).map(word => word[0]).join('').toUpperCase();
  };

  const formatDate = (dateString) => {
    if (!dateString) return t('No date');
    try {
      return formatDistanceToNow(new Date(dateString), { addSuffix: true });
    } catch (error) {
      console.error('Error formatting date:', error);
      return t('Invalid date');
    }
  };

  const renderProjectCard = (project) => (
    <motion.div
      key={project.id}
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Card
        hoverable
        style={{ 
          height: '100%',
          borderTop: `3px solid ${project.color}`,
          borderRadius: '8px',
          overflow: 'hidden'
        }}
        onClick={() => navigate(`/projects/${project.id}/features`)}
      >
        <div style={{ 
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '16px'
        }}>
          <Avatar size={isMobile ? 48 : 64} style={{ backgroundColor: project.color, fontSize: isMobile ? '18px' : '24px' }}>
            {getInitials(project.name)}
          </Avatar>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="1" icon={<EditOutlined />} onClick={(e) => {
                  e.stopPropagation();
                  setCurrentProject(project);
                  setEditProjectModalVisible(true);
                }}>
                  {t('Edit')}
                </Menu.Item>
                <Menu.Item key="2" icon={<DeleteOutlined />} onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteProject(project);
                }}>
                  {t('Delete')}
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <Button size="small" icon={<EditOutlined />} onClick={e => e.stopPropagation()} />
          </Dropdown>
        </div>
        <Title level={isMobile ? 5 : 4} style={{ marginBottom: '8px' }}>{project.name}</Title>
        <Text type="secondary" style={{ display: 'block', marginBottom: '16px', fontSize: isMobile ? '12px' : '14px' }}>
          {t('Created')}: {formatDate(project.createdAt)}
        </Text>
        <Space wrap>
          {project.tags && project.tags.map((tag, index) => (
            <Tag key={index} style={{ marginBottom: 4, fontSize: isMobile ? '10px' : '12px' }}>{tag}</Tag>
          ))}
        </Space>
      </Card>
    </motion.div>
  );

  const renderContent = () => {
    if (projects.length === 0) {
      return (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t('No projects found')}
        >
          <Button type="primary" icon={<PlusOutlined />} onClick={() => setAddProjectModalVisible(true)}>
            {t('Add Project')}
          </Button>
        </Empty>
      );
    }

    return (
      <Row gutter={[24, 24]}>
        {filteredAndSortedProjects.map(project => (
          <Col xs={24} sm={12} md={8} lg={6} key={project.id}>
            {renderProjectCard(project)}
          </Col>
        ))}
      </Row>
    );
  };

  const renderHeader = () => (
    <Header style={{ background: '#fff', padding: '0 24px', boxShadow: '0 2px 8px rgba(0,0,0,0.06)' }}>
      <Row justify="space-between" align="middle" style={{ height: '100%' }}>
        <Col>
          <Title level={3} style={{ margin: 0 }}>{t('Projects')}</Title>
        </Col>
        <Col>
          {isMobile ? (
            <Space size="large">
              <Button icon={<MenuOutlined />} onClick={() => setDrawerVisible(true)} />
              <Button type="primary" icon={<PlusOutlined />} onClick={() => setAddProjectModalVisible(true)} />
            </Space>
          ) : (
            <Space size="large">
              <Input
                placeholder={t("Search projects")}
                prefix={<SearchOutlined />}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ width: 200 }}
              />
              <Select
                style={{ width: 120 }}
                placeholder={t("Filter by tag")}
                onChange={(value) => setFilterTag(value)}
                defaultValue="All"
              >
                {allTags.map(tag => (
                  <Option key={tag} value={tag}>{tag}</Option>
                ))}
              </Select>
              <Select
                style={{ width: 120 }}
                placeholder={t("Sort by")}
                onChange={(value) => setSortBy(value)}
                defaultValue="name"
              >
                <Option value="name">{t("Name")}</Option>
                <Option value="date">{t("Date")}</Option>
              </Select>
              <Button 
                icon={sortOrder === 'asc' ? <SortAscendingOutlined /> : <SortAscendingOutlined rotate={180} />} 
                onClick={() => setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc')}
              />
              <Button type="primary" icon={<PlusOutlined />} onClick={() => setAddProjectModalVisible(true)}>
                {t('Add Project')}
              </Button>
            </Space>
          )}
        </Col>
      </Row>
    </Header>
  );

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
    <VerticalMenu />
    <div style={{ flex: 1, padding: '20px', overflowY: 'auto'}}>
    <div style={{ display: 'flex', height: '100vh' }}>
      
      <Layout style={{ minHeight: '100vh', background: '#fff' }}>
        {renderHeader()}
        <Content style={{ padding: '24px', overflowY: 'auto' }}>
          {isMobile && (
            <Input
              placeholder={t("Search projects")}
              prefix={<SearchOutlined />}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ width: '100%', marginBottom: '16px' }}
            />
          )}
          {renderContent()}
        </Content>

        <Modal
          title={t("Add New Project")}
          visible={addProjectModalVisible}
          onOk={handleAddProject}
          onCancel={() => setAddProjectModalVisible(false)}
          width={isMobile ? 320 : 500}
        >
          <Space direction="vertical" style={{ width: '100%' }} size="large">
            <Input
              placeholder={t("Project Name")}
              value={newProject.name}
              onChange={(e) => setNewProject({ ...newProject, name: e.target.value })}
            />
            <div>
              <Text>{t("Project Color")}</Text>
              <ColorPicker
                value={newProject.color}
                onChange={(color) => setNewProject({ ...newProject, color: color.toHexString() })}
                presets={[
                  {
                    label: t('Recent Colors'),
                    colors: recentColors
                  },
                  {
                    label: t('Recommended'),
                    colors: COLORS
                  }
                ]}
              />
            </div>
            <div>
              <Text>{t("Project Tags")}</Text>
              <Select
                mode="tags"
                style={{ width: '100%' }}
                placeholder={t("Add tags")}
                onChange={(tags) => setNewProject({ ...newProject, tags })}
                options={allTags.filter(tag => tag !== 'All').map(tag => ({ value: tag, label: tag }))}
              />
            </div>
          </Space>
        </Modal>

        <Modal
          title={t("Edit Project")}
          visible={editProjectModalVisible}
          onOk={handleEditProject}
          onCancel={() => setEditProjectModalVisible(false)}
          width={isMobile ? 320 : 500}
        >
          <Space direction="vertical" style={{ width: '100%' }} size="large">
            <Input
              placeholder={t("Project Name")}
              value={currentProject?.name}
              onChange={(e) => setCurrentProject({ ...currentProject, name: e.target.value })}
              />
              <div>
                <Text>{t("Project Color")}</Text>
                <ColorPicker
                  value={currentProject?.color}
                  onChange={(color) => setCurrentProject({ ...currentProject, color: color.toHexString() })}
                  presets={[
                    {
                      label: t('Recent Colors'),
                      colors: recentColors
                    },
                    {
                      label: t('Recommended'),
                      colors: COLORS
                    }
                  ]}
                />
              </div>
              <div>
                <Text>{t("Project Tags")}</Text>
                <Select
                  mode="tags"
                  style={{ width: '100%' }}
                  placeholder={t("Add tags")}
                  value={currentProject?.tags}
                  onChange={(tags) => setCurrentProject({ ...currentProject, tags })}
                  options={allTags.filter(tag => tag !== 'All').map(tag => ({ value: tag, label: tag }))}
                />
              </div>
            </Space>
          </Modal>
  
          <Drawer
            title={t("Menu")}
            placement="right"
            onClose={() => setDrawerVisible(false)}
            visible={drawerVisible}
          >
            <Space direction="vertical" style={{ width: '100%' }} size="large">
              <Button type="primary" icon={<PlusOutlined />} onClick={() => {
                setAddProjectModalVisible(true);
                setDrawerVisible(false);
              }} block>
                {t('Add Project')}
              </Button>
              <Select
                style={{ width: '100%' }}
                placeholder={t("Filter by tag")}
                onChange={(value) => {
                  setFilterTag(value);
                  setDrawerVisible(false);
                }}
                defaultValue="All"
              >
                {allTags.map(tag => (
                  <Option key={tag} value={tag}>{tag}</Option>
                ))}
              </Select>
              <Select
                style={{ width: '100%' }}
                placeholder={t("Sort by")}
                onChange={(value) => {
                  setSortBy(value);
                  setDrawerVisible(false);
                }}
                defaultValue="name"
              >
                <Option value="name">{t("Name")}</Option>
                <Option value="date">{t("Date")}</Option>
              </Select>
              <Button 
                icon={sortOrder === 'asc' ? <SortAscendingOutlined /> : <SortAscendingOutlined rotate={180} />} 
                onClick={() => {
                  setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc');
                  setDrawerVisible(false);
                }}
                block
              >
                {sortOrder === 'asc' ? t('Ascending') : t('Descending')}
              </Button>
            </Space>
          </Drawer>
        </Layout>
      </div>
      </div>
      </div>
    );
  }
  
  export default ProjectsPage;