import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

import Dashboard from './components/Dashboard';
import Register from './components/Auth/Register';
import Login from './components/Auth/Login';
import Settings from './components/settings';
import NotesPage from './components/projects/NotesPage';
import FolderPage from './components/FolderPage';
import ProjectsPage from './components/projects/ProjectsPage';
import TaskBoard from './components/taskboard/TaskBoard';
import Task from './components/taskboard/TaskCard';
import Column from './components/taskboard/Column';
import DealBoard from './components/dealboard/DealBoard';
import DealCard from './components/dealboard/DealCard';
import Dealcolumn from './components/dealboard/Dealcolumn';
import FeaturesPage from './components/projects/FeaturesPage';
import Contacts from './components/Contacts';
import Companies from './components/company/Companies';
import Invoice from './components/company/Invoice';
import TasksPage from './components/projects/TasksPage';
import RoadmapPage from './components/projects/RoadmapPage';
import RoadmapProjects from './components/projects/RoadmapProjects';
import Company from './components/company/company';
import Dealstable from './components/company/dealstable';
import SortableItem from './components/SortableItem';
import AllTask from './components/projects/AllTask';
import NoteContent from './components/projects/NoteContent';
import LoadingPage from './loading/LoadingPage';
import NotFound from './components/NotFound'; 
import Unauthorized from './components/Unauthorized';

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingPercentage, setLoadingPercentage] = useState(0);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <LoadingPage loadingPercentage={loadingPercentage} />;
  }

  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <AppRoutes user={user} />
      </Router>
    </I18nextProvider>
  );
}

function AppRoutes({ user }) {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="*" element={<ProtectedRoute user={user} />} />
    </Routes>
  );
}

function ProtectedRoute({ user }) {
  const location = useLocation();

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return (
    <Routes>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/" element={<ProjectsPage />} />
      <Route path="/SortableItem" element={<SortableItem />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/notes" element={<NotesPage />} />
      <Route path="/notes/:folderId" element={<FolderPage />} />
      <Route path="/projects/:projectId/features" element={<FeaturesPage />} />
      <Route path="/projects/:projectId/features/:featureId/tasks" element={<TasksPage />} />
      <Route path="/projects/:projectId/roadmap" element={<RoadmapPage />} />
      <Route path="/roadmapprojects" element={<RoadmapProjects />} />
      <Route path="/projects/:projectId/AllTask" element={<AllTask />} />
      <Route path="/note-content/:noteId" element={<NoteContent />} />
      <Route path="/company/:companyId" element={<Company />} />
      <Route path="/TaskCard" element={<Task />} />
      <Route path="/Column" element={<Column />} />
      <Route path="/taskboard" element={<TaskBoard />} />
      <Route path="/DealCard" element={<DealCard />} />
      <Route path="/invoices/:invoiceId" element={<Invoice />} />
      <Route path="/Dealcolumn" element={<Dealcolumn />} />
      <Route path="/DealBoard" element={<DealBoard />} />
      <Route path="/Companies" element={<Companies />} />
      <Route path="/Contacts" element={<Contacts />} />
      <Route path="/dealstable" element={<Dealstable />} />
      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;