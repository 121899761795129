import React, { useEffect, useState, useRef } from 'react';
import { collection, getDocs, query, where, doc, updateDoc, onSnapshot, addDoc } from 'firebase/firestore';
import { db, auth } from '../../firebase';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import VerticalMenu from '../VerticalMenu';
import Column from './Column';
import TaskCard from './TaskCard';
import { Modal, Select, Button, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

function TaskBoard() {
  const [tasks, setTasks] = useState({});
  const [columns, setColumns] = useState({
    todo: [],
    inProgress: [],
    done: [],
  });
  const [activeId, setActiveId] = useState(null);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState('all');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newTaskTitle, setNewTaskTitle] = useState('');
  const { t } = useTranslation();

  const columnPhaseMapping = {
    todo: 'To Do',
    inProgress: 'In Progress',
    done: 'Done',
  };

  useEffect(() => {
    const fetchProjects = async () => {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        console.error('No user is signed in.');
        return;
      }
      const userId = currentUser.uid;

      const querySnapshot = await getDocs(query(collection(db, "projects"), where("userId", "==", userId)));
      const fetchedProjects = [];
      querySnapshot.forEach((doc) => {
        fetchedProjects.push({ id: doc.id, ...doc.data() });
      });
      setProjects(fetchedProjects);
    };

    fetchProjects();
  }, []);

  useEffect(() => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.error('No user is signed in.');
      return;
    }
    const userId = currentUser.uid;

    let tasksQuery = collection(db, "tasks");
    let queryConstraints = [where("userId", "==", userId)];
    
    if (selectedProject !== 'all') {
      queryConstraints.push(where("projectId", "==", selectedProject));
    }

    const q = query(tasksQuery, ...queryConstraints);

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const fetchedTasks = {};
      const columnTasks = {
        todo: [],
        inProgress: [],
        done: [],
      };

      querySnapshot.forEach((doc) => {
        const taskData = doc.data();
        fetchedTasks[doc.id] = { ...taskData, id: doc.id };
        
        switch (taskData.phase) {
          case 'To Do':
            columnTasks.todo.push(doc.id);
            break;
          case 'In Progress':
            columnTasks.inProgress.push(doc.id);
            break;
          case 'Done':
            columnTasks.done.push(doc.id);
            break;
          default:
            break;
        }
      });

      setTasks(fetchedTasks);
      setColumns(columnTasks);
    }, (error) => {
      console.error("Failed to subscribe to task updates:", error);
    });

    return () => unsubscribe();
  }, [selectedProject]);

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = async (event) => {
    const { active, over } = event;
  
    if (!over) {
      setActiveId(null);
      return;
    }
  
    const fromColumnId = findColumn(active.id);
    const toColumnId = over.id;
    const newPhase = columnPhaseMapping[toColumnId];
  
    if (fromColumnId !== toColumnId) {
      setColumns((prev) => {
        const fromTasks = prev[fromColumnId].filter((taskId) => taskId !== active.id);
        const toTasks = [...prev[toColumnId], active.id];
        
        return {
          ...prev,
          [fromColumnId]: fromTasks,
          [toColumnId]: toTasks,
        };
      });

      // Update the task in Firebase
      const taskRef = doc(db, "tasks", active.id);
      try {
        await updateDoc(taskRef, {
          phase: newPhase
        });
        console.log(`Task ${active.id} moved to ${newPhase}.`);
      } catch (error) {
        console.error("Error updating task phase: ", error);
      }
    }
  
    setActiveId(null);
  };
  
  const findColumn = (taskId) => {
    return Object.keys(columns).find(columnId => columns[columnId].includes(taskId));
  };
  
  const convertColumnIdToTitle = (columnId) => {
    const titles = {
      todo: t('To Do'),
      inProgress: t('In Progress'),
      done: t('Done'),
    };
  
    return titles[columnId] || 'Unknown';
  };

  const addNewTask = async () => {
    if (newTaskTitle.trim() === '') return;

    const newTask = {
      title: newTaskTitle,
      description: '',
      phase: 'To Do',
      userId: auth.currentUser.uid,
      projectId: selectedProject === 'all' ? null : selectedProject,
      createdAt: new Date(),
    };

    try {
      const docRef = await addDoc(collection(db, 'tasks'), newTask);
      console.log("New task added with ID: ", docRef.id);
      setNewTaskTitle('');
      setIsModalVisible(false);
    } catch (error) {
      console.error("Error adding new task: ", error);
    }
  };

  return (
    <div style={{ display: 'flex', height: '100vh', backgroundColor: '#f0f2f5' }}>
      <VerticalMenu />
      <div style={{ flex: 1, padding: '20px', overflowY: 'auto', marginTop: '50px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
          <Select
            value={selectedProject}
            onChange={(value) => setSelectedProject(value)}
            style={{ width: 200 }}
          >
            <Option value="all">{t('All Projects')}</Option>
            {projects.map(project => (
              <Option key={project.id} value={project.id}>{project.name}</Option>
            ))}
          </Select>
          <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsModalVisible(true)}>
            {t('Add Task')}
          </Button>
        </div>

        <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
          <div style={{ display: 'flex', gap: '20px' }}>
            {Object.entries(columns).map(([columnId, taskIds]) => (
              <Column
                key={columnId}
                columnId={columnId}
                title={convertColumnIdToTitle(columnId)}
                taskIds={taskIds}
                tasks={tasks}
              />
            ))}
          </div>
          <DragOverlay>
            {activeId ? <TaskCard key={activeId} id={activeId} task={tasks[activeId]} onUpdate={setTasks} /> : null}
          </DragOverlay>
        </DndContext>

        <Modal
          title={t("Add New Task")}
          visible={isModalVisible}
          onOk={addNewTask}
          onCancel={() => setIsModalVisible(false)}
        >
          <Input
            placeholder={t("Task Title")}
            value={newTaskTitle}
            onChange={(e) => setNewTaskTitle(e.target.value)}
          />
        </Modal>
      </div>
    </div>
  );
}

export default TaskBoard;