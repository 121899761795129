import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, onSnapshot, addDoc, doc, updateDoc, deleteDoc, query, where, setDoc, getDoc, getDocs } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { db, auth } from '../../firebase';
import VerticalMenu from '../VerticalMenu';
import { writeBatch } from 'firebase/firestore';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Modal, Input, Button, Card, Tag, Space, Typography, Checkbox, 
  Progress, Popover, Row, Col, AutoComplete, Divider, Select, message,
  Layout, Dropdown, Menu, Tooltip, ColorPicker, Popconfirm, Empty, Drawer
} from 'antd';
import { 
  PlusOutlined, ArrowLeftOutlined, EditOutlined, 
  DeleteOutlined, MoreOutlined, FilterOutlined, SortAscendingOutlined,
  ClockCircleOutlined, CheckCircleOutlined, SettingOutlined, MenuOutlined
} from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';

const { Text, Title } = Typography;
const { Header, Content } = Layout;
const { Option } = Select;

const LABEL_COLORS = [
  "#FF5733", "#33FF57", "#3357FF", "#F333FF", "#FFBD33", "#33FFBD", "#5733FF", 
  "#FF33A1", "#33D4FF", "#FF8F33", "#33FF6B", "#A133FF", "#FF335E", "#33FFD4", 
  "#338AFF", "#FFD433"
];

const PRIORITY_COLORS = {
  High: '#ff4d4f',
  Normal: '#faad14',
  Low: '#52c41a',
};

const PHASES = ['To Do', 'In Progress', 'Done'];
const PRIORITIES = ['Low', 'Normal', 'High'];

function TasksPage() {
  const { t } = useTranslation();
  const { projectId, featureId } = useParams();
  const navigate = useNavigate();
  const [tasks, setTasks] = useState([]);
  const [feature, setFeature] = useState({ name: '', color: '', tags: [] });
  const [selectedTask, setSelectedTask] = useState(null);
  const [taskDetails, setTaskDetails] = useState({
    title: '',
    description: '',
    labels: [],
    priority: 'Normal',
    phase: 'To Do',
    checklist: []
  });
  const [taskModalVisible, setTaskModalVisible] = useState(false);
  const [featureSettingsModalVisible, setFeatureSettingsModalVisible] = useState(false);
  const [allLabels, setAllLabels] = useState([]);
  const [newLabel, setNewLabel] = useState({ text: '', color: LABEL_COLORS[0] });
  const [filterPhase, setFilterPhase] = useState('All');
  const [filterPriority, setFilterPriority] = useState('All');
  const [sortBy, setSortBy] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('desc');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const userId = auth.currentUser.uid;

  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    const fetchTasks = () => {
      const tasksRef = collection(db, 'tasks');
      const q = query(tasksRef, where('featureId', '==', featureId));
      return onSnapshot(q, snapshot => {
        const fetchedTasks = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setTasks(fetchedTasks);
      });
    };

    const fetchFeature = async () => {
      const featureRef = doc(db, 'features', featureId);
      const featureDoc = await getDoc(featureRef);
      if (featureDoc.exists()) {
        setFeature({ id: featureDoc.id, ...featureDoc.data() });
      }
    };

    const fetchAllLabels = async () => {
      const labelsQuery = query(collection(db, 'labels'), where('userId', '==', userId));
      const labelsSnap = await getDocs(labelsQuery);
      const labels = labelsSnap.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setAllLabels(labels);
    };

    const unsubscribeTasks = fetchTasks();
    fetchFeature();
    fetchAllLabels();

    return () => unsubscribeTasks();
  }, [featureId, userId]);

  const resetTaskDetails = () => {
    setTaskDetails({
      title: '',
      description: '',
      labels: [],
      priority: 'Normal',
      phase: 'To Do',
      checklist: []
    });
  };

  const handleAddTask = async () => {
    if (taskDetails.title.trim() && taskDetails.description.trim()) {
      const newTask = { 
        ...taskDetails, 
        labels: taskDetails.labels || [],
        checklist: taskDetails.checklist || [],
        featureId, 
        userId, 
        createdAt: new Date(),
        projectId 
      };
      await addDoc(collection(db, 'tasks'), newTask);
      await logActivity('Task added', `Added ${taskDetails.title}`);
      resetTaskDetails();
      setTaskModalVisible(false);
      message.success(t('Task added successfully'));
    }
  };

  const handleUpdateTask = async () => {
    if (selectedTask && taskDetails.title.trim() && taskDetails.description.trim()) {
      const taskRef = doc(db, 'tasks', selectedTask.id);
      await updateDoc(taskRef, {
        ...taskDetails,
        labels: taskDetails.labels || [],
        checklist: taskDetails.checklist || []
      });
      await logActivity('Task updated', `Updated ${taskDetails.title}`);
      setTaskModalVisible(false);
      message.success(t('Task updated successfully'));
    }
  };

  const handleArchiveTask = async (task) => {
    const taskToArchive = task || selectedTask;
    if (taskToArchive) {
      const archiveRef = doc(db, 'archive', taskToArchive.id);
      await setDoc(archiveRef, { ...taskToArchive, archivedAt: new Date().toISOString() });
      await deleteDoc(doc(db, 'tasks', taskToArchive.id));
      await logActivity('Task archived', `Archived ${taskToArchive.title}`);
      setSelectedTask(null);
      setTaskModalVisible(false);
      message.success(t('Task archived successfully'));
    }
  };

  const logActivity = async (type, details) => {
    const activity = {
      type,
      details,
      timestamp: new Date(),
      userId,
      featureId,
      projectId
    };
    await addDoc(collection(db, 'activities'), activity);
  };

  const showTaskModal = (task = null) => {
    setSelectedTask(task);
    if (task) {
      setTaskDetails({
        ...task,
        checklist: task.checklist || []
      });
    } else {
      resetTaskDetails();
    }
    setTaskModalVisible(true);
  };

  const handleTaskDetailsChange = (field, value) => {
    setTaskDetails(prev => ({ ...prev, [field]: value }));
  };

  const handleChecklistChange = (index, field, value) => {
    const newChecklist = [...taskDetails.checklist];
    newChecklist[index] = { ...newChecklist[index], [field]: value };
    handleTaskDetailsChange('checklist', newChecklist);
  };

  const addChecklistItem = () => {
    handleTaskDetailsChange('checklist', [...taskDetails.checklist, { text: '', checked: false }]);
  };

  const removeChecklistItem = (index) => {
    const newChecklist = taskDetails.checklist.filter((_, i) => i !== index);
    handleTaskDetailsChange('checklist', newChecklist);
  };

  const handleAddLabel = async () => {
    if (newLabel.text.trim()) {
      const labelRef = doc(collection(db, 'labels'), newLabel.text);
      await setDoc(labelRef, { ...newLabel, userId });
      setAllLabels(prev => [...prev, { ...newLabel, userId }]);
      handleTaskDetailsChange('labels', [...taskDetails.labels, newLabel]);
      setNewLabel({ text: '', color: LABEL_COLORS[0] });
      message.success(t('Label added successfully'));
    }
  };

  const removeLabel = (labelToRemove) => {
    const updatedLabels = taskDetails.labels.filter(label => label.text !== labelToRemove.text);
    handleTaskDetailsChange('labels', updatedLabels);
  };

  const handleUpdateFeature = async () => {
    const featureRef = doc(db, 'features', featureId);
    await updateDoc(featureRef, {
      name: feature.name,
      color: feature.color,
      tags: feature.tags
    });
    message.success(t('Feature updated successfully'));
    setFeatureSettingsModalVisible(false);
  };

  const handleDeleteFeature = async () => {
    try {
      const tasksRef = collection(db, 'tasks');
      const tasksQuery = query(tasksRef, where('featureId', '==', featureId));
      const tasksSnapshot = await getDocs(tasksQuery);
      
      const batch = writeBatch(db);
      tasksSnapshot.forEach((taskDoc) => {
        batch.delete(doc(db, 'tasks', taskDoc.id));
      });
      batch.delete(doc(db, 'features', featureId));
      
      await batch.commit();
      message.success(t('Feature and its tasks deleted successfully'));
      navigate(`/projects/${projectId}/features`);
    } catch (error) {
      console.error("Error deleting feature and tasks:", error);
      message.error(t('Error deleting feature and tasks'));
    }
  };

  const filteredAndSortedTasks = useMemo(() => {
    return tasks
      .filter(task => 
        (filterPhase === 'All' || task.phase === filterPhase) &&
        (filterPriority === 'All' || task.priority === filterPriority)
      )
      .sort((a, b) => {
        if (a[sortBy] < b[sortBy]) return sortOrder === 'asc' ? -1 : 1;
        if (a[sortBy] > b[sortBy]) return sortOrder === 'asc' ? 1 : -1;
        return 0;
      });
  }, [tasks, filterPhase, filterPriority, sortBy, sortOrder]);



  const labelContent = (
    <div style={{ maxWidth: '300px' }}>
      <AutoComplete
        style={{ width: '100%', marginBottom: '10px' }}
        options={allLabels.map(label => ({ value: label.text }))}
        filterOption={(inputValue, option) =>
          option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
        onSelect={(value) => {
          const selectedLabel = allLabels.find(label => label.text === value);
          if (selectedLabel && !taskDetails.labels.some(l => l.text === selectedLabel.text)) {
            handleTaskDetailsChange('labels', [...taskDetails.labels, selectedLabel]);
          }
        }}
        placeholder={t("Search or create new label")}
      />
      <Divider />
      <Input
        placeholder={t("New label name")}
        value={newLabel.text}
        onChange={(e) => setNewLabel(prev => ({ ...prev, text: e.target.value }))}
        style={{ marginBottom: '10px' }}
      />
      <Row gutter={[8, 8]}>
        {LABEL_COLORS.map(color => (
          <Col span={6} key={color}>
            <div
              onClick={() => setNewLabel(prev => ({ ...prev, color }))}
              style={{
                backgroundColor: color,
                height: '20px',
                cursor: 'pointer',
                border: newLabel.color === color ? '2px solid #000' : 'none',
                borderRadius: '4px'
              }}
            />
          </Col>
        ))}
      </Row>
      <Button onClick={handleAddLabel} style={{ marginTop: '10px', width: '100%' }}>
        {t("Add Label")}
      </Button>
    </div>
  );
  const taskList = useMemo(() => (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px', justifyContent: 'flex-start' }}>
      {filteredAndSortedTasks.length === 0 ? (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '40px 0' }}>
          <Empty
            description={
              <Space direction="vertical" align="center">
                <Text>{t("No tasks found")}</Text>
                <Button type="primary" icon={<PlusOutlined />} onClick={() => showTaskModal()}>
                  {t("Add Task")}
                </Button>
              </Space>
            }
          />
        </div>
      ) : (
        <AnimatePresence>
          {filteredAndSortedTasks.map(task => (
            <motion.div
              key={task.id}
              layout
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
              transition={{ duration: 0.3 }}
              style={{ width: isMobile ? '100%' : 'calc(25% - 12px)', minWidth: isMobile ? 'auto' : '250px', marginBottom: '16px' }}
            >
              <Card
                hoverable
                onClick={() => showTaskModal(task)}
                style={{ 
                  width: '100%',
                  height: '100%',
                  borderRadius: 8, 
                  overflow: 'hidden',
                  display: 'flex',
                  flexDirection: 'column'
                }}
                bodyStyle={{ 
                  padding: 16, 
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                  <Space direction="vertical" size="small" style={{ width: '100%', height: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Text strong style={{ fontSize: 18, maxWidth: 'calc(100% - 32px)' }} ellipsis>{task.title}</Text>
                      <Dropdown overlay={
                        <Menu>
                          <Menu.Item key="1" icon={<EditOutlined />} onClick={(e) => { e.stopPropagation(); showTaskModal(task); }}>
                            {t("Edit")}
                          </Menu.Item>
                          <Menu.Item key="2" icon={<DeleteOutlined />} onClick={(e) => { e.stopPropagation(); handleArchiveTask(task); }}>
                            {t("Archive")}
                          </Menu.Item>
                        </Menu>
                      }>
                        <Button icon={<MoreOutlined />} type="text" onClick={e => e.stopPropagation()} />
                      </Dropdown>
                    </div>
                    <Text type="secondary" ellipsis={{ rows: 2 }}>{task.description}</Text>
                    <div style={{ flex: 1, overflow: 'auto' }}>
                      <Space wrap style={{ marginTop: 8 }}>
                        {task.labels && task.labels.map((label, index) => (
                          <Tag key={index} color={label.color} style={{ borderRadius: 12, margin: '0 4px 4px 0' }}>
                            {label.text}
                          </Tag>
                        ))}
                      </Space>
                    </div>
                    <Space style={{ marginTop: 'auto' }}>
                      <Tag color={PRIORITY_COLORS[task.priority]} style={{ borderRadius: 12 }}>{task.priority}</Tag>
                      <Tag icon={task.phase === 'Done' ? <CheckCircleOutlined /> : <ClockCircleOutlined />} style={{ borderRadius: 12 }}>
                        {task.phase}
                      </Tag>
                    </Space>
                    <Progress 
                      percent={Math.round((task.checklist?.filter(item => item.checked).length || 0) / (task.checklist?.length || 1) * 100)}
                      size="small"
                      status="active"
                      strokeColor={{
                        '0%': '#108ee9',
                        '100%': '#87d068',
                      }}
                    />
                  </Space>
                </div>
              </Card>
            </motion.div>
          ))}
        </AnimatePresence>
      )}
    </div>
  ), [filteredAndSortedTasks, isMobile]);

  const renderHeader = () => (
    <Header style={{ background: '#fff', padding: '0 16px', boxShadow: '0 2px 8px rgba(0,0,0,0.06)' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '100%' }}>
        <Space>
          <Button type="link" icon={<ArrowLeftOutlined />} onClick={() => navigate(`/projects/${projectId}/features`)}>
            {t("Back")}
          </Button>
          <Title level={4} style={{ margin: 0, fontSize: isMobile ? '18px' : '24px' }}>{feature.name} {t("Tasks")}</Title>
        </Space>
        {isMobile ? (
          <Space>
            <Button icon={<MenuOutlined />} onClick={() => setDrawerVisible(true)} />
            <Button type="primary" icon={<PlusOutlined />} onClick={() => showTaskModal()} />
          </Space>
        ) : (
          <Space>
            <Tooltip title={t("Feature Settings")}>
              <Button 
                icon={<SettingOutlined />} 
                onClick={() => setFeatureSettingsModalVisible(true)}
              />
            </Tooltip>
            <Select 
              style={{ width: 120 }} 
              value={filterPhase}
              onChange={setFilterPhase}
              placeholder={t("Filter Phase")}
            >
              <Option value="All">{t("All Phases")}</Option>
              {PHASES.map(phase => (
                <Option key={phase} value={phase}>{t(phase)}</Option>
              ))}
            </Select>
            <Select 
              style={{ width: 120 }} 
              value={filterPriority}
              onChange={setFilterPriority}
              placeholder={t("Filter Priority")}
            >
              <Option value="All">{t("All Priorities")}</Option>
              {PRIORITIES.map(priority => (
                <Option key={priority} value={priority}>{t(priority)}</Option>
              ))}
            </Select>
            <Select 
              style={{ width: 120 }} 
              value={sortBy}
              onChange={setSortBy}
              placeholder={t("Sort By")}
            >
              <Option value="createdAt">{t("Creation Date")}</Option>
              <Option value="title">{t("Title")}</Option>
              <Option value="priority">{t("Priority")}</Option>
            </Select>
            <Button 
              icon={sortOrder === 'asc' ? <SortAscendingOutlined /> : <SortAscendingOutlined rotate={180} />} 
              onClick={() => setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc')}
            />
            <Button type="primary" icon={<PlusOutlined />} onClick={() => showTaskModal()}>
              {t("Add Task")}
            </Button>
          </Space>
        )}
      </div>
    </Header>
  );

  return (
    <div style={{ display: 'flex', height: '100vh', backgroundColor: '#f5f5f5' }}>
      {!isMobile && <VerticalMenu />}
      <div style={{ flex: 1, overflowY: 'auto', display: 'flex', flexDirection: 'column' }}>
        <Layout style={{ minHeight: '100vh', background: '#f0f2f5' }}>
          {renderHeader()}
          <Content style={{ padding: '16px', maxWidth: '100%', margin: '0 auto' }}>
            {taskList}
          </Content>
          <Modal
            title={selectedTask ? t("Edit Task") : t("Add Task")}
            visible={taskModalVisible}
            onOk={selectedTask ? handleUpdateTask : handleAddTask}
            onCancel={() => setTaskModalVisible(false)}
            width={700}
            bodyStyle={{ maxHeight: '80vh', overflowY: 'auto' }}
          >
            <Space direction="vertical" style={{ width: '100%' }} size="middle">
              <Input
                placeholder={t("Task Title")}
                value={taskDetails.title}
                onChange={(e) => handleTaskDetailsChange('title', e.target.value)}
              />
              <Input.TextArea
                placeholder={t("Task Description")}
                value={taskDetails.description}
                onChange={(e) => handleTaskDetailsChange('description', e.target.value)}
                rows={4}
              />
              <Select
                style={{ width: '100%' }}
                value={taskDetails.priority}
                onChange={(value) => handleTaskDetailsChange('priority', value)}
              >
                {PRIORITIES.map(priority => (
                  <Option key={priority} value={priority}>{t(priority)}</Option>
                ))}
              </Select>
              <Select
                style={{ width: '100%' }}
                value={taskDetails.phase}
                onChange={(value) => handleTaskDetailsChange('phase', value)}
              >
                {PHASES.map(phase => (
                  <Option key={phase} value={phase}>{t(phase)}</Option>
                ))}
              </Select>

              <Popover
                content={labelContent}
                title={t("Manage Labels")}
                trigger="click"
              >
                <Button type="dashed" style={{ width: '100%' }}>
                  <PlusOutlined /> {t("Manage Labels")}
                </Button>
              </Popover>
              <Space wrap>
                {taskDetails.labels.map((label, index) => (
                  <Tag 
                    key={index} 
                    color={label.color}
                    closable
                    onClose={() => removeLabel(label)}
                    style={{ borderRadius: 12 }}
                  >
                    {label.text}
                  </Tag>
                ))}
              </Space>

              <Title level={5}>{t("Checklist")}</Title>
              <Progress 
                percent={Math.round((taskDetails.checklist?.filter(item => item.checked).length || 0) / (taskDetails.checklist?.length || 1) * 100)}
                size="small"
                status="active"
                strokeColor={{
                  '0%': '#108ee9',
                  '100%': '#87d068',
                }}
              />
              {taskDetails.checklist.map((item, index) => (
                <Space key={index} style={{ width: '100%' }}>
                  <Checkbox
                    checked={item.checked}
                    onChange={(e) => handleChecklistChange(index, 'checked', e.target.checked)}
                  />
                  <Input
                    value={item.text}
                    onChange={(e) => handleChecklistChange(index, 'text', e.target.value)}
                    style={{ width: '100%' }}
                  />
                  <Button 
                    icon={<DeleteOutlined />} 
                    onClick={() => removeChecklistItem(index)}
                  />
                </Space>
              ))}
              <Button onClick={addChecklistItem} icon={<PlusOutlined />}>
                {t("Add Checklist Item")}
              </Button>

              {selectedTask && (
                <Button danger onClick={() => handleArchiveTask(selectedTask)}>
                  {t("Archive Task")}
                </Button>
              )}
            </Space>
          </Modal>

          <Modal
            title={t("Feature Settings")}
            visible={featureSettingsModalVisible}
            onOk={handleUpdateFeature}
            onCancel={() => setFeatureSettingsModalVisible(false)}
            width={500}
            bodyStyle={{ maxHeight: '80vh', overflowY: 'auto' }}
          >
            <Space direction="vertical" style={{ width: '100%' }} size="large">
              <div>
                <Title level={5}>{t("Feature Name")}</Title>
                <Input
                  value={feature.name}
                  onChange={(e) => setFeature({ ...feature, name: e.target.value })}
                />
              </div>
              <div>
                <Title level={5}>{t("Feature Color")}</Title>
                <ColorPicker
                  value={feature.color}
                  onChange={(color) => setFeature({ ...feature, color: color.toHexString() })}
                />
              </div>
              <div>
                <Title level={5}>{t("Feature Tags")}</Title>
                <Select
                  mode="tags"
                  style={{ width: '100%' }}
                  placeholder={t("Add tags")}
                  value={feature.tags}
                  onChange={(tags) => setFeature({ ...feature, tags })}
                />
              </div>
              <Divider />
              <div>
                <Title level={5} style={{ color: '#ff4d4f' }}>{t("Danger Zone")}</Title>
                <Popconfirm
                  title={t("Are you sure you want to delete this feature and all its tasks?")}
                  onConfirm={handleDeleteFeature}
                  okText={t("Yes")}
                  cancelText={t("No")}
                  okButtonProps={{ danger: true }}
                >
                  <Button danger icon={<DeleteOutlined />}>
                    {t("Delete Feature")}
                  </Button>
                </Popconfirm>
              </div>
            </Space>
          </Modal>
          <Drawer
            title={t("Task Options")}
            placement="right"
            onClose={() => setDrawerVisible(false)}
            visible={drawerVisible}
            width={isMobile ? '100%' : 300}
          >
            <Space direction="vertical" style={{ width: '100%' }} size="middle">
              <Button type="primary" icon={<PlusOutlined />} onClick={() => {
                showTaskModal();
                setDrawerVisible(false);
              }} block>
                {t("Add Task")}
              </Button>
              <Tooltip title={t("Feature Settings")}>
                <Button 
                  icon={<SettingOutlined />} 
                  onClick={() => {
                    setFeatureSettingsModalVisible(true);
                    setDrawerVisible(false);
                  }}
                  block
                >
                  {t("Feature Settings")}
                </Button>
              </Tooltip>
              <Select 
                style={{ width: '100%' }} 
                value={filterPhase}
                onChange={(value) => {
                  setFilterPhase(value);
                  setDrawerVisible(false);
                }}
                placeholder={t("Filter Phase")}
              >
                <Option value="All">{t("All Phases")}</Option>
                {PHASES.map(phase => (
                  <Option key={phase} value={phase}>{t(phase)}</Option>
                ))}
              </Select>
              <Select 
                style={{ width: '100%' }} 
                value={filterPriority}
                onChange={(value) => {
                  setFilterPriority(value);
                  setDrawerVisible(false);
                }}
                placeholder={t("Filter Priority")}
              >
                <Option value="All">{t("All Priorities")}</Option>
                {PRIORITIES.map(priority => (
                  <Option key={priority} value={priority}>{t(priority)}</Option>
                ))}
              </Select>
              <Select 
                style={{ width: '100%' }} 
                value={sortBy}
                onChange={(value) => {
                  setSortBy(value);
                  setDrawerVisible(false);
                }}
                placeholder={t("Sort By")}
              >
                <Option value="createdAt">{t("Creation Date")}</Option>
                <Option value="title">{t("Title")}</Option>
                <Option value="priority">{t("Priority")}</Option>
              </Select>
              <Button 
                icon={sortOrder === 'asc' ? <SortAscendingOutlined /> : <SortAscendingOutlined rotate={180} />} 
                onClick={() => {
                  setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc');
                  setDrawerVisible(false);
                }}
                block
              >
                {sortOrder === 'asc' ? t('Sort Ascending') : t('Sort Descending')}
              </Button>
            </Space>
          </Drawer>
        </Layout>
      </div>
    </div>
  );
}

export default TasksPage;